import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'waitinglisttest'

const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)

const GetAll = () => SocialProjectManager.get(resourceName)

const GetAllPendingOrApproved = pagination => SocialProjectManager.get(`${resourceName}/pending-or-approved`, { 
  params: pagination
})

const GetAllBySearch = data => SocialProjectManager.get(`${resourceName}/search`, data)

const Create = data => SocialProjectManager.post(resourceName, data)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const AbsentTest = id => SocialProjectManager.put(`${resourceName}/absent/${id}`)

const WaitingListTestRepository = {
  GetById,
  GetAll,
  GetAllBySearch,
  Create,
  Update,
  Delete,
  GetAllPendingOrApproved,
  AbsentTest
}

export default WaitingListTestRepository