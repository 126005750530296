import StudentDeficiencyRepository from '@/shared/http/repositories/socialProject/student-deficiency'
import TableRegistration from '@/views/student/components/TableRegistration/tableRegistration.vue'
import WaitingListTestRepository from '@/shared/http/repositories/socialProject/waitinglist-test'
import ContactTypeRepository from '@/shared/http/repositories/socialProject/contact-type'
import WaitingListRepository from '@/shared/http/repositories/socialProject/waitinglist'
import TableContact from '@/views/student/components/TableContact/tableContact.vue'
import GeneralInfo from '@/views/student/components/GeneralInfo/generalInfo.vue'
import Anamnese from '@/views/student/components/Anamnese/anamnese.vue'
import StudentRepository from '@/shared/http/repositories/socialProject/student'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import SendSms from '@/components/layout/SendSms/sendSms.vue'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import Student from '@/shared/models/student'
import Roles from '@/shared/mixins/hasRoles'
import GuidUtils from '@/shared/utils/guid'
import { groupBy } from 'lodash'
import moment from 'moment'

import { mapActions } from 'vuex'
import { globalActions as StudentActions } from '@/store/modules/student/actionTypes'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [SendSms.name]: SendSms,
    [GeneralInfo.name]: GeneralInfo,
    [TableContact.name]: TableContact,
    [TableRegistration.name]: TableRegistration,
    [Anamnese.name]: Anamnese
  },

  mixins: [Roles],

  data: () => ({
    student: new Student(),
    emptyText: 'Nenhum Teste Cadastrado',
    waitingListTest: [],
    contactTypes: [],
    childrens: [],
    selectedRow: [],
    answeredQuestion: null,
    totalRegister: 0,
    colsNumber: null,
    onShowModalMatriculate: false,
    selectedChildrenWaitingListId: null,
    selectedChildrenWaitingListTestId: null,
    isNew: true,
    onShowModalSendSMS: false,
    isStudentSms: false,
    showModalKeepRegisterInWaitingList: false,
    studentExist: false,
    specialCharacters: [
      {str: '@RESPNAME@'},
      {str: '@STUDENTNAME@'},
      {str: '@DATETEST@'},
      {str: '@DATENOW@'},
      {str: '@DATETOMORROW@'},
      {str: '@DATEYESTERDAY@'}
    ],
    showSaveButton: false,
		fields: [
      {
        key: 'name',
        label: 'NOME DA CRIANÇA',
        class: 'left-header-border text-center'
      },
      {
        key: 'result',
        label: 'RESULTADO',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'dateLimitTest',
        label: 'DATA LIMITE TESTE',
        class: 'middle-header text-center',
        sortable: true,
        formatter: (value, key, item) => {
          if (!item) return '-'

          const dateTest = item.date

          let dateLimitTest = moment(dateTest).add(10,'days').format("YYYY/MM/DD HH:mm")
          const todayDate = moment().format("YYYY/MM/DD HH:mm")
          const compareDates = todayDate.valueOf() > dateLimitTest.valueOf()

          if(todayDate == dateLimitTest || compareDates) {
            return 'showButtonAbsentTest'
          }

          return dateLimitTest = moment(dateTest).add(10,'days').format("DD/MM/YYYY HH:mm")
        }
      },
      {
        key: 'matriculate',
        label: 'MATRÍCULA',
        class: 'right-header-border text-center'
      }
    ]
  }),

  provide() {
    return { parentValidator: this.$validator }
  },

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
					{
            text: 'Listagem de Espera',
            href: this.$router.resolve({ name: 'WaitingList' }).href
          },
          {
            text: 'Resultados dos Testes - Lista de Espera',
            active: true
          }
        ]
      }
		}
	},

  created() {
    this.getAllContactTypes()
		this.GetAllNotReproved()
    this.$validator.reset()
  },

  methods: {
    ...mapActions({
      setStudent: StudentActions.SET_STUDENT
    }),

		GetAllNotReproved() {
      loading.push()
      WaitingListTestRepository.GetAllPendingOrApproved()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

					const response = res.data.data
          this.totalRegister = res.data.count

          this.waitingListTest = Object.values(groupBy(response, x => x.classId))

          if (this.waitingListTest.length <= 1){
            this.colsNumber = '12'
          } else {
            this.colsNumber = '6'
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          toast.error(err, 'ERRO')
        })
    },

    onInactiveTest(id, onShowMessage) {
      loading.push()
      WaitingListTestRepository.Delete(id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if (onShowMessage){
            toast.info('Teste deletado com sucesso!', 'DELETAR TESTE')
          }
          this.GetAllNotReproved()

          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          toast.error(err, 'ERRO')
        })
    },

    onAbsentTest(id) {
      loading.push()
      WaitingListTestRepository.AbsentTest(id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.info('Teste alterado com sucesso!', 'AUSENTAR TESTE')
          this.GetAllNotReproved()

          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          toast.error(err, 'ERRO')
        })
    },

    matriculateChildren() { this.onSave() },

    onSendSms(item) {
      const onlyChildrenPending = item.filter(x => x.result == 0)

      if (onlyChildrenPending.length == 0) {
        toast.info('Nesta turma não consta criança com teste pendente', 'ENVIO DE SMS NÃO DISPONÍVEL')
        return
      }

      this.childrens = onlyChildrenPending
      this.onShowModalSendSMS = true
    },

    async onCheckOptionModal(item) {
      if (!item.rg || !item) {
        return toast.info('RG em branco.', 'LISTA DE ESPERA')
      }
      this.selectedRow = item

      await this.checkExistStudentByDocument(item.rg)

      this.selectedChildrenWaitingListId = item.waitingListId
      this.selectedChildrenWaitingListTestId = item.id
      this.showModalKeepRegisterInWaitingList = true
    },

    checkAnswerQuestion(answer) {
      this.answeredQuestion = answer
      this.onMatriculateChildren()
    },

    async onMatriculateChildren() {
      await this.getWaitingListById(this.selectedRow)
      this.showModalKeepRegisterInWaitingList = false
      this.onShowModalMatriculate = true
    },

    getAllContactTypes() {
      loading.push()
      ContactTypeRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.contactTypes = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tipos de contato', 'ERRO')
        })
    },

    onCheckTestResult(item, type) {
      if (!item || type !== 'row') return

      if (item.result == 0) {
        return 'table-info'
      }

      if (item.result == 1) {
        return 'table-success'
      }
    },

    onSave() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.info('É necessário preencher os campos obrigatórios antes de continuar', 'AVISO')
          return
        }

        const hasContact = this.student.contacts && this.student.contacts.length > 0

        if (!hasContact) {
          toast.info('É necessário inserir pelo menos um contato antes de continuar', 'AVISO')
          return
        }

        const hasRegistration = this.student.registrations && this.student.registrations.length > 0

        if(!hasRegistration) {
          toast.info('É necessário inserir pelo menos uma matrícula para continuar', 'AVISO')
          return
        }

        const hasDeficiency = this.student.hasDeficiency

        if (hasDeficiency) {
          const validated = this.$refs.GeneralInfo.checkValidatedInputsCid()

          if (!validated) {
            toast.info('É necessário informar qual o CID do aluno e a categoria da deficiência', 'CID - DEFICIÊCIA')
            return
          }
        }

        this.createStudent()
      })
    },

    createStudent() {
      loading.push()

      if (this.student) {

        const hasMeasurement = this.student.height && this.student.weight

        if (hasMeasurement) {
          this.student.height = this.student.height.replace(",",".")
          this.student.weight = this.student.weight.replace(",",".")
        }

        StudentRepository.Create(this.student)
          .then(async res => {
            const isAlreadySaved = !IsSuccessCode(res) && res.data.code === -7

            if (isAlreadySaved) {
              toast.info('Já encontrei este aluno cadastrado. Por isso não sera salvo.', 'ALUNO EXISTENTE')
              loading.pop()
              return Promise.resolve()
            }

            if (!IsSuccessCode(res)) return Promise.reject()

            if (res.data.data.hasDeficiency) {
              this.student.id = res.data.data.id
              this.onSaveStudentDeficiency()
              this.$refs.GeneralInfo.disabledAllInputCid = true
              this.$refs.GeneralInfo.showButtonRemoveDeficiency = true
            } else {
              this.$refs.GeneralInfo.disabledAllInputCid = false
              this.$refs.GeneralInfo.showButtonRemoveDeficiency = false
            }

            const boolAnsweredQuestion = this.answeredQuestion

            if (boolAnsweredQuestion) {
              await this.changeMatriculatedColumn(false)
            } else {
              await this.inactiveWaitingListAndChangeMatriculatedColumn(false)
            }

            toast.success('Aluno criado com sucesso!', 'CRIAR ALUNO')
            this.isNew = false
            this.setStudent(res.data.data)
            this.showModalKeepRegisterInWaitingList = false
            this.selectedRow = []
            this.answeredQuestion = null
            this.$refs.Anamnese.getAnamneseByStudentId(res.data.data.id)

            loading.pop()
            return Promise.resolve()
          })
          .catch(() => {
            loading.pop()
            toast.error('Erro ao criar aluno', 'ERRO')
          })
      }
    },

    doAnsweredQuestion(bool) {
      this.answeredQuestion = bool
      const boolAnsweredQuestion = this.answeredQuestion

      if (boolAnsweredQuestion) {
        this.changeMatriculatedColumn(true)
        return
      }
      this.inactiveWaitingListAndChangeMatriculatedColumn(true)
    },

    async saveAnamnese() {
      await this.$refs.Anamnese.clickInSave()
      await this.onClear()
    },

    onSaveStudentDeficiency() {
      if (GuidUtils.isEmpty(this.student.id)) return

      this.studentDeficiency = {
        id: this.student.deficiencyId,
        studentId: this.student.id,
        deficiencyTypeId: this.student.cidCategory,
        cidCode: this.student.cidCode
      }

      loading.push()
      StudentDeficiencyRepository.Create(this.studentDeficiency)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar deficiência', 'ERRO')
        })
    },

    async changeMatriculatedColumn(showMessage) {
      loading.push()
      const waitinglistTestId = this.selectedRow.id
      await WaitingListRepository.Matriculated(this.selectedChildrenWaitingListId, waitinglistTestId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if(showMessage) {
            toast.success('Tudo certo! A criança foi alterada', 'SUCESSO')
            this.onClear()
          }

          this.GetAllNotReproved()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao mudar a coluna isMatriculated', 'ERRO')
        })
    },

    getContactTypeName(id) {
      if (!id) return

      const contactType = this.contactTypes.filter(x => x.id === id)

        if (contactType.length > 0) {
          const contactTypeName = contactType[0].name
          return contactTypeName
        }
        return
    },

    async getWaitingListById(item) {
      loading.push()
      await WaitingListRepository.GetById(this.selectedChildrenWaitingListId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const waitingList = res.data.data

          this.student.name = waitingList.name
          this.student.gender = waitingList.gender
          this.student.documentRG = waitingList.rg
          this.student.documentCPF = waitingList.cpf
          this.student.district = waitingList.district
          this.student.birthDate = waitingList.birthDate
          this.student.hasDeficiency = waitingList.hasDeficiency
          this.student.cidCode = item.cidCode != null ? item.cidCode[0] : null
          this.student.cidCategory = item.deficiencyTypeId != null ? item.deficiencyTypeId[0] : null
          this.student.contacts = waitingList.contacts.map(x => ({
            phone: x.phone1,
            phone2: x.phone2,
            phone3: x.phone3,
            name: x.name,
            contactTypeId: x.contactTypeId,
            contactTypeName: this.getContactTypeName(x.contactTypeId),
            email: x.email,
            id : x.id
          }))
          this.student.registrations = waitingList.tests.filter(x => x.result == 1).map(x => ({
            classId: x.classId,
            className: x.className,
            dateStart: moment().format(),
            id: x.id,
            localTrainingId: item.localTrainingId,
            localTrainingName: item.localTrainingName,
            categoryId: item.categoryId,
            categoryName: item.categoryName,
            modalityId: x.modalityId,
            modalityName: item.modalityName
          }))

          this.$validator.reset()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar criança', 'ERRO')
        })
    },

    async checkExistStudentByDocument(document) {
      await StudentRepository.GetByDocument(document)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          const response = res.data.data

          if (response) {
            this.studentExist = true
            this.student.id = response.id

          } else {
            this.studentExist = false
            this.student.id = GuidUtils.emptyGuid
          }
          return Promise.resolve()
        })
        .catch(() => {
          toast.error('Erro ao pegar o aluno pelo documento', 'ERRO')
        })
    },

    async inactiveWaitingListAndChangeMatriculatedColumn(showMessage) {
      loading.push()
      const waitingListTestId = this.selectedRow.id
      await WaitingListRepository.DeleteAndMatriculated(this.selectedChildrenWaitingListId, waitingListTestId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.onInactiveTest(this.selectedChildrenWaitingListTestId, false)

          if (showMessage) {
            toast.success('Criança Inativada com Sucesso', 'LISTA DE ESPERA')
            this.onClear()
          }

          this.GetAllNotReproved()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao inativar criança da lista de espera', 'ERRO')
        })
    },

    closeModalIsExistStudent() {
      this.showModalKeepRegisterInWaitingList = false
      this.student.id = GuidUtils.emptyGuid
      this.selectedRow = []
    },

    onClear() {
      this.onShowModalMatriculate = false
      this.selectedChildrenWaitingListId = null
      this.selectedChildrenWaitingListTestId = null
      this.showModalKeepRegisterInWaitingList = false
      this.student = new Student()
      this.isNew = true
      this.answeredQuestion = null
    },

    onCloseModalSendSMS() {
      this.onShowModalSendSMS = false
    }
  }
}
